@import "./fonts/noto-sans.scss";

:root {
  --color-darkest: #000000;
  --color-darkest-rgb: 0, 0, 0;
  --color-iq-tuna: #33303c;
  --color-mine-shaft: #313131;
  --color-dove-gray: #6f6f6f;
  --color-iq-silver: #cdcdcd;
  --color-bon-jour: #e1dfe1;
  --color-wild-sand: #f5f5f5;
  --color-iq-pampas: #f6f4f0;
  --color-soft-white: #fffdfb;
  --color-white: #ffffff;
  --color-iq-blue: #7b61ff;
  --color-turquoise: #00d1d2;
  --color-pomegranate: #f32a2a;
  --color-taste-yellow: #eac702;
  --color-taste-amber: #ca5e21;
  --color-taste-silver: #c9c9c9;
  --color-taste-turquoise: #03b5b6;
  --color-taste-purple: #baa5ce;
  --color-taste-noor: #c37625;
  --color-taste-apricity: #ff9052;
  --color-taste-yugen: #b95ec2;
}

* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
}

$indentationRules: 100 100, 85 85, 65 65, 48 48, 46 46, 40 40, 36 36, 32 32, 24 24, 20 20, 12 12;
$directions: ("t": "top", "b": "bottom", "r": "right", "l": "left");

@each $desktopIndent, $mobileIndenty in $indentationRules {
  @each $attr, $direction in $directions {
    .m#{$attr}-#{$desktopIndent} {
      margin-#{$direction}: #{$desktopIndent}px;
    }

    .p#{$attr}-#{$desktopIndent} {
      padding-#{$direction}: #{$desktopIndent}px;
    }
  }
}

@each $attr, $direction in $directions {
  .m#{$attr}-auto {
    margin-#{$direction}: auto;
  }
}

$fontSizes: 12 12, 14 24, 16 24, 18 24, 24 36, 36 36;
@each $fontSize, $lineHeight in $fontSizes {
  .fs-#{$fontSize}-#{$lineHeight} {
    font-size: #{$fontSize}px;
    line-height: #{$lineHeight}px;
  }
}

$fontWeights: 400, 500, 600, 700;
@each $fontWeight in $fontWeights {
  .fw-#{$fontWeight} {
    font-weight: $fontWeight;
  }
}

$maxWidths: 320, 350, 500, 1000;
@each $maxWidth in $maxWidths {
  .Mw-#{$maxWidth} {
    max-width: #{$maxWidth}px;
  }
}

.fw-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}
